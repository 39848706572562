import { onKeyUpEscape, debounce, pauseAllMedia } from '../js/global-exports.js';

window.megaMenuMinHeight = 0;
class DetailsDisclosure extends HTMLElement {
  constructor() {
    super();
    this.mainDetailsToggle = this.querySelector('details');
    this.isMegaMenuDetailsClosure = this.querySelector('details-disclosure-mega-menu');

    this.addEventListener('keyup', onKeyUpEscape);
    this.mainDetailsToggle.addEventListener('focusout', this.onFocusOut.bind(this));
    if(this.isMegaMenuDetailsClosure) {
      this.megaMenuInit();
    }
    window.addEventListener('resize', debounce(() => {
      window.megaMenuMinHeight = 0;
      this.calculateMinHeight();
    },250));
  }
  calculateMinHeight() {
    let tempMegaMenuForMeasuring = document.createElement('div');
    tempMegaMenuForMeasuring.id='tempMegaMenuForMeasuring'
    tempMegaMenuForMeasuring.style.position='fixed';
    tempMegaMenuForMeasuring.style.top='200vh';
    tempMegaMenuForMeasuring.style.left='200vw';
    const megaMenus = document.getElementsByClassName('mega-menu');
    for (let j = 0; j < megaMenus.length; j++) {
      const clone = megaMenus[j].cloneNode(true);
      tempMegaMenuForMeasuring.appendChild(clone);
    }
    document.body.appendChild(tempMegaMenuForMeasuring)

    const tempMegaMenus = tempMegaMenuForMeasuring.querySelectorAll('#tempMegaMenuForMeasuring .mega-menu');
    for (let m = 0; m < tempMegaMenus.length; m++) {
      const element = tempMegaMenus[m];
        if(element.offsetHeight > window.megaMenuMinHeight) {
          window.megaMenuMinHeight = element.offsetHeight;
        }
    }
    document.getElementById('tempMegaMenuForMeasuring').remove();
    document.documentElement.style.setProperty('--mega-menu-min-height', `${window.megaMenuMinHeight}px`);
  }

  megaMenuInit() {
    if(!window.megaMenuMinHeight) {
      this.calculateMinHeight();
    }

    const megaMenu = this.querySelector('.mega-menu');
    if(megaMenu) {
      megaMenu.addEventListener('click', (e) => {
        if(e.target===megaMenu) {
          this.close();
        }
      })
    }

    this.mainDetailsToggle.addEventListener('toggle',(e)=>{
      if(e.target.open) {
        pauseAllMedia();
      }else{
        document.dispatchEvent(new CustomEvent('restartBackgroundVideos', { bubbles: true }));
      }
    });
  }

  onFocusOut() {
    setTimeout(() => {
      if (!this.contains(document.activeElement)) this.close();
    })
  }

  close() {
    this.mainDetailsToggle.removeAttribute('open');
  }
}

customElements.define('details-disclosure', DetailsDisclosure);

if (!customElements.get('share-button')) {
  customElements.define('share-button', class ShareButton extends DetailsDisclosure {
    constructor() {
      super();

      this.elements = {
        shareButton: this.querySelector('button'),
        shareSummary: this.querySelector('summary'),
        closeButton: this.querySelector('.share-button__close'),
        successMessage: this.querySelector('[id^="ShareMessage"]'),
        urlInput: this.querySelector('input')
      }
      this.urlToShare = this.elements.urlInput && this.elements.urlInput.value ? this.elements.urlInput.value : document.location.href;
      document.addEventListener('remakeShareValueFromUrl', this.remakeShareValueFromUrl.bind(this));

      if(this.elements.urlInput && !this.elements.urlInput.value) {
        this.elements.urlInput.value = this.urlToShare;
      }

      if (navigator.share) {
        this.mainDetailsToggle.setAttribute('hidden', '');
        this.elements.shareButton.classList.remove('hidden');
        this.elements.shareButton.addEventListener('click', () => { navigator.share({ url: this.urlToShare, title: document.title }) });
      } else {
        this.addAccessibilityAttributes();
        this.mainDetailsToggle.addEventListener('toggle', this.toggleDetails.bind(this));
        this.mainDetailsToggle.querySelector('.share-button__copy').addEventListener('click', this.copyToClipboard.bind(this));
        this.mainDetailsToggle.querySelector('.share-button__close').addEventListener('click', this.close.bind(this));
      }
    }

    remakeShareValueFromUrl() {
      this.urlToShare = document.location.href;
      this.elements.urlInput.value = this.urlToShare;
    }

    addAccessibilityAttributes() {
      this.elements.shareSummary.setAttribute('role', 'button');
      this.elements.shareSummary.setAttribute('aria-expanded', 'false');
      this.elements.shareSummary.setAttribute('aria-controls', this.elements.shareSummary.nextElementSibling.id);
    }

    toggleDetails() {
      if (!this.mainDetailsToggle.open) {
        this.elements.successMessage.classList.add('hidden');
        this.elements.successMessage.textContent = '';
        this.elements.closeButton.classList.add('hidden');
        this.elements.shareSummary.focus();
      }
      this.elements.shareSummary.setAttribute('aria-expanded', this.mainDetailsToggle.open);
    }

    copyToClipboard() {
      navigator.clipboard.writeText(this.elements.urlInput.value).then(() => {
        this.elements.successMessage.classList.remove('hidden');
        this.elements.successMessage.textContent = window.accessibilityStrings.shareSuccess;
        this.elements.closeButton.classList.remove('hidden');
        this.elements.closeButton.focus();
      });
    }
  });
}
